import { ButtonHTMLAttributes, FC, ReactNode, useEffect } from 'react'
import clsx from 'clsx'

import { apprenticeTheme, mentorTheme } from 'src/themes/base'
import { applyTheme } from 'src/themes/utils'

export type ButtonTheme =
  | 'normal'
  | 'inverted'
  | 'danger'
  | 'purple'
  | 'inverted-purple'
  | 'white'
  | 'gray'
  | 'green'
  | 'inverted-green'

export type ButtonRounded = 'normal' | 'lg' | 'full'

interface ButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme: ButtonTheme
  width?: 'full' | 'max-content'
  loading?: boolean
  rounded?: ButtonRounded
  margin?: string
  padding?: string
  children?: ReactNode
}

const Spinner = () => (
  <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
)

export const Button: FC<ButtonInterface> = props => {
  const {
    loading,
    theme,
    className,
    width,
    rounded,
    margin,
    padding,
    children,
    disabled,
    ...properties
  } = props
  const defaultClasses = [
    `border ${margin?.length ? margin : 'mx-auto'} ${
      padding?.length ? padding : 'px-6 py-2'
    } flex items-center`
  ]
  useEffect(() => {
    if (theme === 'purple' || theme === 'inverted-purple') {
      applyTheme(mentorTheme)
    } else if (theme === 'normal' || theme === 'inverted') {
      applyTheme(apprenticeTheme)
    }
  }, [])
  let buttonClass = ''

  switch (rounded) {
    case 'normal':
      defaultClasses.push('rounded')
      break
    case 'lg':
      defaultClasses.push('rounded-lg')
      break
    default:
      defaultClasses.push('rounded-full')
  }

  if (typeof width === 'undefined' || width === 'max-content') {
    defaultClasses.push('w-max')
  } else {
    defaultClasses.push('w-full justify-center')
  }

  switch (theme) {
    case 'inverted':
      buttonClass = `w-max ${
        rounded?.length ? rounded : 'rounded-full'
      } bg-white text-qureosSecondary border-qureosSecondary`
      break
    case 'normal':
      buttonClass = 'bg-qureosPrimary text-white border-qureosPrimary'
      break
    case 'danger':
      buttonClass = `w-max ${
        rounded?.length ? rounded : 'rounded-full'
      } bg-red-600 text-white border-red-600`
      break
    case 'purple':
      buttonClass =
        'bg-qureosPrimary text-white hover:text-qureosPrimary border-qureosPrimary hover:bg-white'
      break
    case 'inverted-purple':
      buttonClass = `w-max ${
        rounded?.length ? rounded : 'rounded-full'
      } bg-white text-qureosPrimary border-qureosPrimary hover:bg-qureosPrimary hover:text-white`
      break
    case 'green':
      buttonClass =
        'bg-qureosPrimaryLight text-white border-qureosPrimary hover:opacity-80'
      break
    case 'inverted-green':
      buttonClass = `w-max ${
        rounded?.length ? rounded : 'rounded-full'
      } bg-white text-qureosPrimary border-qureosPrimary hover:bg-qureosPrimary hover:text-white`
      break
    case 'white':
      buttonClass = 'bg-white text-black border-black hover:bg-gray-100'
      break
    case 'gray':
      buttonClass =
        'bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:border-black'
      break
    default:
  }

  if (disabled && loading) {
    buttonClass = 'border-gray-300 bg-gray-300 cursor-not-allowed'
  }

  if (disabled) {
    buttonClass = 'border-gray-300 bg-gray-300 cursor-not-allowed'
  }

  return (
    <button
      className={clsx(defaultClasses, buttonClass, className)}
      disabled={disabled || loading}
      {...properties}
      data-testid="button-test">
      {loading && <Spinner />}
      {children}
    </button>
  )
}
