import { FC } from 'react'
import clsx from 'clsx'

export interface Props {
  text: any
  className?: string
  required?: boolean
  optional?: boolean
}

export const Label: FC<{ data: Props }> = ({ data }) => {
  const { text, className, required, optional } = data
  const hasTextGrey = className && className.includes('text-gray')
  return (
    <label
      className={clsx('m-0', className, {
        'text-gray-800': !hasTextGrey
      })}>
      {text}
      {required && (
        <span className="text-red-500 text-xs pl-1 inline-flex m-0">*</span>
      )}{' '}
      {optional && (
        <div className="text-gray-500 flex-1 text-right text-sm">Optional</div>
      )}
    </label>
  )
}
