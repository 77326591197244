import { ReactNode } from 'react'

type IApprenticeCarouselData = {
  image: string
  title: string
  buttonComponent?: ReactNode
  backgroundColor?: string
}

export const apprenticeCarouselData: IApprenticeCarouselData[] = [
  {
    image:
      'https://storage.googleapis.com/qureos-prod/static/banners/ApprenticeCarouselImageJobs.png',
    title: ''
  },
  {
    image:
      'https://storage.googleapis.com/qureos-prod/static/banners/ApprenticeCarouselImageMentorship.png',
    title: ''
  },
  {
    image:
      'https://storage.googleapis.com/qureos-prod/static/banners/ApprenticeCarouselImageProjects.png',
    title: ''
  }
]

export const apprenticeCarouselDataBlue: IApprenticeCarouselData[] =
  apprenticeCarouselData
