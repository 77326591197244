import { ReactNode, FC, useRef, useState } from 'react'
import 'antd/dist/antd.css'
import { Carousel } from 'antd'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { ShouldRender } from '@/components/shared/misc'

type CarouselDataProps = {
  image: string
  title: string
  buttonComponent?: ReactNode
  backgroundColor?: string
}

type CarouselProps = {
  data: CarouselDataProps[]
  showPagination: boolean
  autoplay: boolean
} & typeof defaultProps

const defaultProps = {
  autoplay: false
}

const QureosCarousel: FC<CarouselProps> = ({
  data,
  showPagination,
  autoplay
}) => {
  const carouselRef = useRef(null)
  const [paginationCount, setPaginationCount] = useState<number>(1)
  return (
    <div className="flex flex-col lg:w-[620px] w-full">
      <Carousel
        dots={false}
        ref={carouselRef}
        style={{
          maxWidth: '620px',
          width: '100%',
          height: '355px',
          overflow: 'hidden',
          borderRadius: '15px'
        }}
        autoplay={autoplay}
        afterChange={currentIndex => {
          setPaginationCount(currentIndex + 1)
        }}
      >
        {data.map((carouselData: CarouselDataProps, index: number) => {
          const { image, title, buttonComponent } = carouselData
          return (
            <div key={index}>
              <div
                className="flex flex-col flex-col-reverse items-start pl-8 pb-8"
                style={{
                  width: '100%',
                  height: '340px',
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '15px'
                }}
              >
                <ShouldRender check={buttonComponent}>
                  {buttonComponent}
                </ShouldRender>
                <div className="pb-4 text-white font-bold w-1/2 text-3xl">
                  {title}
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
      {showPagination && (
        <div className="flex justify-end mt-3">
          <span
            className="text-gray-700 flex items-center justify-center w-5 h-5 cursor-pointer"
            onClick={() => {
              if (paginationCount > 1) {
                setPaginationCount(paginationCount - 1)
                carouselRef.current.prev()
              }
            }}
          >
            <ChevronLeftIcon className="w-6 h-10 text-gray-500" />
          </span>
          <span className="flex justify-center items-center mx-1.5 text-[14px, 20px] text-gray-700">
            <span className="font-medium inline-flex">{paginationCount}</span>
            <span className="inline-flex mx-1 leading-tight">{' / '}</span>
            <span className="text-[14px, 20px] font-medium inline-flex">
              {data.length}
            </span>
          </span>
          <span
            className="text-gray-700 flex items-center justify-center w-5 h-5 cursor-pointer"
            onClick={() => {
              if (paginationCount < data.length) {
                setPaginationCount(paginationCount + 1)
                carouselRef.current.next()
              }
            }}
          >
            <ChevronRightIcon className="w-6 h-10 text-gray-500" />
          </span>
        </div>
      )}
    </div>
  )
}

QureosCarousel.defaultProps = defaultProps

export default QureosCarousel
