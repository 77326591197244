import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { Input } from '@/components/shared/basic/Input'
import { Label } from '@/components/shared/misc/Typography/Label'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { ShouldRender } from '../ShouldRender'

export interface FieldProps {
  label?: string
  required?: boolean
  optional?: boolean
  type?: React.HTMLInputTypeAttribute
  placeholder?: string
  value?: any
  maxLength?: number
  onChange?: (value: string) => void
  labelClass?: string
  inputContainerClassName?: string
  className?: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  disabled?: boolean
  error?: ReactNode | string
  inputFieldName?: string
  children?: ReactNode
  showCount?: boolean
}

export const Field: FC<FieldProps> = ({
  label,
  required,
  optional,
  placeholder,
  type,
  children,
  value,
  onChange,
  labelClass,
  inputContainerClassName,
  className,
  maxLength,
  startAdornment,
  endAdornment,
  disabled,
  error,
  inputFieldName,
  showCount = false
}) => {
  const [inputValue, setInputValue] = useState<string>(value || '')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const calledOnce = useRef(false)

  const handleValueChange = (data: string) => {
    setInputValue(data)
    onChange && onChange(data)
  }

  useEffect(() => {
    if (value && !calledOnce.current) {
      setInputValue(value)
      calledOnce.current = true
    } else if (!isEqual(value, inputValue)) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className="w-full flex flex-col gap-1">
      {label && (
        <Label
          data={{
            text: label,
            required,
            optional,
            className: labelClass
          }}
        />
      )}
      {type && (
        <>
          <div
            className={clsx(
              'inline-flex items-center',
              inputContainerClassName
            )}
          >
            {startAdornment && startAdornment}
            <div
              className={clsx(
                'w-full',
                type === 'password' &&
                  'border rounded-md inline-flex border-gray-300',
                isFocused && 'outline-none border-blue-600'
              )}
            >
              <Input
                disabled={disabled}
                placeholder={placeholder}
                name={inputFieldName}
                onChange={e => handleValueChange(e.target.value)}
                type={type === 'password' && showPassword ? 'text' : type}
                maxLength={maxLength}
                value={inputValue || ''}
                className={`${className} ${
                  type === 'password'
                    ? 'border-none outline-none pr-0 my-0'
                    : ''
                }`}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              <ShouldRender check={type === 'password'}>
                {!showPassword ? (
                  <EyeIcon
                    width={20}
                    height={20}
                    className=" self-center mx-2 cursor-pointer"
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <EyeSlashIcon
                    width={20}
                    height={20}
                    className="self-center mx-2 cursor-pointer"
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </ShouldRender>
            </div>
            {endAdornment && endAdornment}
          </div>
          <ShouldRender check={showCount}>
            <div className={clsx('inline-flex justify-end', labelClass)}>
              {inputValue?.length ?? 0}/{maxLength}
            </div>
          </ShouldRender>
          <ShouldRender check={error}>
            <div className="inline-flex items-center text-red-500">{error}</div>
          </ShouldRender>
        </>
      )}
      {children}
    </div>
  )
}
