import React, { FC } from 'react'
import clsx from 'clsx'

import style from './Input.module.scss'

export const Input: FC<React.InputHTMLAttributes<HTMLInputElement>> = props => {
  const { className, disabled, ...otherProps } = props
  return (
    <input
      disabled={disabled}
      {...otherProps}
      className={clsx(
        style.qureosInput,
        { 'bg-gray-200': disabled },
        className
      )}
    />
  )
}
