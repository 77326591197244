import { toast, ToastContent } from 'react-toastify'

type NotifyProps = {
  message: string | ToastContent
  type: 'info' | 'success' | 'warning' | 'error'
}

export const notify = ({ message, type }: NotifyProps): void => {
  toast.dismiss()

  switch (type) {
    case 'info':
      toast.info(message)
      break
    case 'success':
      toast.success(message)
      break
    case 'warning':
      toast.warn(message)
      break
    case 'error':
      toast.error(message)
      break
    default:
      toast(message)
      break
  }
}
