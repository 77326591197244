import { FC } from 'react'

interface PhotoProps {
  url: string
  title: string
  className?: string
}

export const Photo: FC<PhotoProps> = ({ url, title, className }) => {
  return <img className={className} src={url} alt={title} />
}
